import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import QrPreview from "./QrPreview";

const CTable = () => {
  const [data, setData] = useState([]);
  const [dataQR, setDataQR] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/product");
        const filteredData = response.data.filter(
          (item) => item.code && item.code.startsWith("S"),
        );
        setData(filteredData);
        setDataQR(response.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "NO",
      selector: (row, index) => index + 1 + paginationStartIndex,
      sortable: true,
    },
    {
      name: "BATCH",
      selector: (row) => (row.batch ? row.batch : "-"),
      sortable: true,
    },

    {
      name: "CODE",
      selector: (row) => (row.code ? row.code : "-"),
      sortable: true,
    },
    {
      name: "SALES",
      selector: (row) => (row.sales ? row.sales : "Available"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        if (row.sales === "sold") {
          cellStyle.color = "#0CAB58";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        }
        return <div style={cellStyle}>{row.sales ? row.sales : "-"}</div>;
      },
    },
    {
      name: "ALL - QR",
      cell: (row) => (
        <span
          onClick={() => handleDownloadQRAll(row.qrid)}
          style={{ backgroundColor: "green" }}
          className="btn btn-download-qr text-white"
        >
          Download
        </span>
      ),
      sortable: false,
    },

    {
      name: "ACTION",
      cell: (row) => (
        <span
          onClick={() => handleDelete(row.qrid, row.batch)}
          style={{ backgroundColor: "red" }}
          className="btn btn-download-qr text-white px-4"
        >
          Delete
        </span>
      ),
      sortable: false,
    },
  ];

  const handleDelete = async (qrid, batch) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await axios.post(`/delete-product/${qrid}/${batch}`);
        window.location.reload();
        console.log("Product deleted successfully");
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };

  const [qrDownload, setqrDownload] = useState([]);
  const [qrModal, setQrModal] = useState(false);

  const handleDownloadQRAll = (qrid) => {
    console.log("Downloading QR for qrid:", qrid);
    const filteredAll = dataQR.filter((item) => item.qrid === qrid);
    setqrDownload(filteredAll);
    setQrModal(true);
  };

  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const handlePageChange = (page) => {
    setPaginationStartIndex((page - 1) * 10);
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationTotalRows={data.length}
        onChangePage={handlePageChange}
      />
      <QrPreview QrCode={qrDownload} QrModal={qrModal} />
    </div>
  );
};

export default CTable;
